import { http } from "@/services/http";
import responses from "@/services/responses";
import routes from "@/services/routes";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import Constants from "@/utils/constants";

export const registrationCode = {
  invite(code: string, email: string): Promise<string> {
    return http
      .post(`${routes().REGISTRATION}`, {
        code: code,
        email: email,
        statusType: Constants.registrationStatusType.Invited,
      })
      .then(() => {
        return responses.SUCCESS;
      })
      .catch((err) => {
        return err.response.data.error;
      });
  },

  cancelInvitation(code: string, email: string): Promise<string> {
    return http
      .post(`${routes().REGISTRATION_CANCEL}`, { code: code, email: email })
      .then(() => {
        return responses.SUCCESS;
      })
      .catch((err) => {
        return err.response.data.error;
      });
  },

  redeem(code: string): Promise<string> {
    return http
      .post(`${routes().REGISTRATION}`, { code })
      .then(() => {
        return responses.SUCCESS;
      })
      .catch((err) => {
        return err.response.data.error;
      });
  },

  validate(code: string): Promise<{ res: string; event: EventContentModel | null }> {
    return http
      .post<{ valid: boolean; event: EventContentModel }>(`${routes().REGISTRATION}/validate_event`, { code })
      .then((response) => {
        const event = response.data.event;
        return { res: responses.SUCCESS, event: event };
      })
      .catch((error) => {
        return { res: `${error.response.data.errorCode}`, event: null };
      });
  },
};
