import { Module } from "vuex";
import { SdiState } from "./model";
import api from "@/services/api";

const SdiModule: Module<SdiState, unknown> = {
  namespaced: true,
  state(): SdiState {
    return {
      platformLinkHref: "https://app.corestrengths.com/",
      platformLinkTarget: "win-clp-sdi-app",
    };
  },
  getters: {
    getSdiPlatformLinkHref(state: SdiState): string {
      return state.platformLinkHref;
    },
    getSdiPlatformLinkTarget(state: SdiState): string {
      return state.platformLinkTarget;
    },
  },
  mutations: {
    setSdiState(state: SdiState, sdiStateOptions: Partial<SdiState>): void {
      Object.assign(state, sdiStateOptions);
    },
  },
  actions: {
    async fetchSdiPlatformLink({ commit }): Promise<void> {
      const loginUrl = await api.event.getSdiLoginUrl();
      if (loginUrl) {
        commit("setSdiState", { platformLinkHref: loginUrl });
      }
    },
  },
};

export default SdiModule;
