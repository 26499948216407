const Constants = {
  eventType: {
    onDemand: "ON_DEMAND",
    hybrid: "HYBRID",
    inPerson: "IN_PERSON",
    materialsOnly: "MATERIALS_ONLY",
    practiceVirtual: "PRACTICE_VIRTUAL",
    virtual: "VIRTUAL",
    assessmentOnly: "ASSESSMENT_ONLY",
  },
  myEventType: {
    myCreated: "MY_CREATED",
    myRedeemed: "MY_REDEEMED",
  },
  familyIdType: {
    SDIAssessment: "bltb5e780e832b1b05b",
    CrucialTeams: "blt3fd62c3ced49734c",
    CrucialConversations: "blt3d4b5275b8473e21",
    CrucialInfluence: "blt63e05587b936b78a",
    ThePowerOfHabit: "blt94a1f944194982ec",
    GettingThingsDone: "blt7b8de9823605d3e0",
    Accountability: "blta6b5210d6f360b5a",
  },
  registrationStatusType: {
    Invited: "INVITED",
    Registered: "REGISTERED",
    InProgress: "IN_PROGRESS",
    Completed: "COMPLETED",
  },
};

export default Constants;
