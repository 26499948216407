<template lang="pug">
Popup(
  ok="submit",
  title="Complete your profile",
  height="auto",
  :okDisabled="disableSubmit",
  :visible="true",
  @ok="onSubmit"
)
  .incomplete-profile-popup
    div
      span Welcome
    div 
      span To access the Crucial Learning Platform, please complete your profile by adding your first and last name.
    TextBox(
      :value="firstName",
      :required="true",
      valueChangeEvent="input",
      label="First Name",
      placeholder="First Name",
      data-name="first-name-input",
      @valueChanged="firstName = $event"
    )
    TextBox(
      :value="lastName",
      :required="true",
      valueChangeEvent="input",
      label="Last Name",
      placeholder="Last Name",
      data-name="last-name-input",
      @valueChanged="lastName = $event"
    )
</template>
<script lang="ts">
import { PutUser } from "@/store/modules/Security/model";
import { BasePopup as Popup, BaseTextBox as TextBox } from "@cruciallearning/puddle";
import { Vue, Component } from "vue-property-decorator";
import { mapActions } from "vuex";
@Component({
  components: { Popup, TextBox },
  methods: {
    ...mapActions("SecurityModule", ["putUser"]),
  },
})
export default class IncompleteProfile extends Vue {
  private readonly putUser!: PutUser;
  private firstName = "";
  private lastName = "";

  get disableSubmit(): boolean {
    return !this.firstName || !this.lastName;
  }
  onSubmit(): void {
    this.putUser({
      securityId: this.$auth.authUser.id,
      firstName: this.firstName,
      lastName: this.lastName,
    });
    this.$auth.refreshAuth();
  }
}
</script>
<style lang="scss" scoped>
.incomplete-profile-popup {
  span {
    color: var(--gray-80);
    font-size: 16px;
    line-height: 140%;
  }
  div:first-child {
    span {
      font-weight: 600;
    }
  }
  div {
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
}
</style>
